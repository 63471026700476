<template>
  <div class="report-env">
    <div class="left">
      <div class="title">选择地块</div>
      <div class="list-box">
        <div class="li add" @click="pickItems">
          <div class="icon-box"><img src="../../img/add.png" alt="" /></div>
          <div>添加地块</div>
        </div>
        <div class="li" v-for="(item, index) in currentItemList" :key="index">
          <div class="label">
            <div class="circle" :style="{ background: item.color }"></div>
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="top">
        <div id="assetChart"></div>
        <div class="nullData loading-sty" v-show="dataLoading || loading">
          <a-spin />
        </div>
        <!-- <div
          class="nullData"
          v-if="currentItemList.length < 1 || reportData.chart.length == 0"
        >
          {{
            currentItemList.length > 0
              ? "暂无数据。"
              : "暂无数据，请先选择监测项。"
          }}
        </div> -->
        <rb-empty
          v-if="currentItemList.length < 1 || reportData.chart.length == 0"
          :tips="currentItemList.length > 0 ? '暂无数据。' : '暂无数据，请先选择监测项。' "
        />
        <!-- <a-empty v-if="currentItemList.length < 1 || reportData.chart.length == 0" image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"/> -->

      </div>
      <div class="bottom">
        <div class="b-item">
          <div class="label">农资使用数量统计</div>
          <div class="b-content">
            <div class="nullData loading-sty" v-show="dataLoading">
              <a-spin />
            </div>
            <!-- <div
              class="nullData"
              v-if="currentItemList.length < 1 || reportData.detail.length == 0"
            >
              {{
                currentItemList.length > 0
                  ? "暂无数据。"
                  : "暂无数据，请先选择监测项。"
              }}
            </div> -->
        <rb-empty
          v-if="currentItemList.length < 1 || reportData.detail.length == 0"
          :tips="currentItemList.length > 0 ? '暂无数据。' : '暂无数据，请先选择监测项。' "
        />
            <div class="total-box">
              <div
                class="t-item"
                v-for="(ditem, dindex) in reportData.detail"
                :key="dindex"
              >
                <div class="info-item info-left">{{ dindex + 1 }}</div>
                <div class="info-item info-center">{{ ditem.item }}</div>
                <div class="info-item">{{ ditem.value }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="title">统计时间段</div>
      <div class="select-box">
        <div
          class="s-item"
          v-for="(item, index) in dateList"
          :key="index"
          @click="pickDateType(item)"
        >
          <div class="icon">
            <img
              :src="
                item.flag == dateFlag
                  ? require('../../img/pick.png')
                  : require('../../img/unpick.png')
              "
              alt=""
            />
          </div>
          <div class="label">{{ item.label }}</div>
        </div>
        <div class="item-box" v-if="dateFlag == 'set'">
          <a-date-picker
            placeholder="请选择开始日期"
            v-model="startTimeStr"
            style="margin-bottom: 8px"
            format="YYYY-MM-DD 00:00:00"
            @change="changeStarTime"
          />
          <a-date-picker
            placeholder="请选择结束日期"
            v-model="endTimeStr"
            format="YYYY-MM-DD 23:59:59"
            @change="changeEndTime"
          />
        </div>
        <div class="item-box">
          <a-button
            size="small"
            style="margin-right: 8px; margin-bottom: 8px"
            @click="searchByDate('reset')"
          >
            重置
          </a-button>
          <a-button type="primary" size="small" @click="searchByDate">
            确认
          </a-button>
        </div>
      </div>
    </div>
    <!-- 选择项box -->
    <a-modal
      title="选择地块"
      width="800px"
      :visible="itemFlag"
      :confirm-loading="itemLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      class="a-modal-sty"
    >
      <a-table
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columns"
        :pagination="pagination"
        :data-source="itemList"
        size="small"
      >
        <template slot="imgPathAll" slot-scope="row">
          <div class="column-img-box">
            <img class="col-img" :src="row" alt="" />
          </div>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";
export default {
  props: {
    history: {},
    colorlist: [],
  },
  data() {
    return {
      loading: false,
      dataLoading: false,
      chart: null,
      typeChart: null,
      searchData: {},
      dateFlag: "day",
      startTimeStr: "",
      endTimeStr: "",
      pickNum: 0,
      itemList: [],
      itemLoading: false,
      itemFlag: false,
      currentItemList: [],
      dateList: [
        {
          label: "近一日",
          flag: "day",
        },
        {
          label: "近七日日",
          flag: "week",
        },
        {
          label: "近一个月",
          flag: "month",
        },
        {
          label: "自定义",
          flag: "set",
        },
      ],
      reportData: {
        chart: [],
        detail: [],
      },
      // 图表颜色列表
      colorList: [],
        pagination:{
            current: 1,
            pageSize: 10,
            showSizeChanger: true, // 显示可改变每页数量
            pageSizeOptions: ['5', '10', '20', '30'], // 每页数量选项
            showTotal: total => `共 ${total} 条`, // 显示总数
            onShowSizeChange: (current, pageSize) => {this.pagination.pageSize = pageSize;this.pagination.current=current},
            onChange: (current, pageSize) => {this.pagination.pageSize = pageSize;this.pagination.current=current},
        },
      columns: [
        {
          title: "地块名称",
          dataIndex: "name",
        },
      ],
      selectedRowKeys: [],
    };
  },
  created() {},
  mounted() {
    this.colorList = this.colorlist;
    let history = JSON.parse(JSON.stringify(this.history));
    if (history.itemList.length > 0) {
      this.itemList = history.itemList;
      this.currentItemList = history.currentItemList;
      this.dateFlag = history.dateFlag;
      this.startTimeStr = history.startTimeStr;
      this.endTimeStr = history.endTimeStr;
      this.selectedRowKeys = history.selectedRowKeys;
      this.handleOk();
    } else {
      // 获取种植品种
      this.getFarmParcelList();
    }
  },
  methods: {
    // 获取种植品种
    getFarmParcelList() {
      this.http.farmParcel.getFarmParcelList().then((res) => {
        if (res.data.status == 200) {
          let list = res.data.data;
          console.log(list);
          list.forEach((val) => {
            val.key = val.id;
          });
          this.itemList = list;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    // 获取图表数据
    getData() {
      // 加载
      this.dataLoading = true;
      // 初始化图表
      this.loading = true;

      let data = {};
      let farmParcelId = [];
      let list = this.currentItemList;
      list.forEach((val) => {
        farmParcelId.push(val.id);
      });
      data.farmParcelId = farmParcelId.join(",");
      if (this.dateFlag != "set") {
        data.day = this.dateFlag;
      } else {
        data.startTimeStr = this.startTimeStr;
        data.endTimeStr = this.endTimeStr;
      }
      this.http.report
        .getAssetWarehouseReport(data)
        .then((res) => {
          if (res.data.status == "200") {
            let data = res.data.data;
            data.chart != null ? (data.chart = data.chart) : (data.chart = []);
            data.detail != null
              ? (data.detail = data.detail)
              : (data.detail = []);
            this.reportData = data;

            // this.itemFlag = false;
            this.dataLoading = false;
            this.loading = false;
            this.itemLoading = false;
            // console.log(data.chart,data.growingEnvironmentPercentage,data.warning);
          } else {
            this.loading = false;
            // this.$message.error("获取监测数据超时");
          }
        })
        .then(() => {
          // this.initFarmDeviceChart();
          this.initColor();
          this.saveSearch();
          this.initChart();
        })
        .catch((err) => {
          this.loading = false;
          // this.$message.error("获取监测数据超时");
          console.log(err);
        });
    },
    saveSearch() {
      this.$emit("childFn", {
        itemList: this.itemList,
        currentItemList: this.currentItemList,
        dateFlag: this.dateFlag,
        startTimeStr: this.startTimeStr,
        endTimeStr: this.endTimeStr,
        selectedRowKeys: this.selectedRowKeys,
      });
    },
    initColor() {
      let data = this.reportData;
      data.chart = this.setColor(data.chart);
      data.detail = this.setColor(data.detail);
      this.reportData = data;
    },
    setColor(clist) {
      clist == null ? (clist = []) : "";
      // 追加颜色
      let colorlist = this.colorList;
      let ci = 0;
      for (let i = 0; i < clist.length; i++) {
        clist[i].color = colorlist[ci];
        if (ci < 11) {
          ci++;
        } else {
          ci = 0;
        }
      }
      return clist;
    },
    initChart() {
      let that = this;
      let rdata = JSON.parse(JSON.stringify(this.reportData.chart));
      if (rdata.length < 1) {
        return;
      }
      if(that.chart != null){
        that.chart.data(rdata)
        that.chart.render()
        that.loading = false;
        return;
      }
      that.chart = new Chart({
        container: "assetChart",
        autoFit: true,
        height: 500,
      });

      that.chart.data(rdata);
      that.chart.scale("value", {
        nice: true,
      });

      that.chart.tooltip({
        showMarkers: false,
      });
      that.chart.interaction("active-region");

      that.chart.interval().position("item*value");

      that.$nextTick(() => {
        that.chart.render();
        that.loading = false;
      });
    },
    pickDateType(val) {
      this.dateFlag = val.flag;
    },
    changeStarTime(date, dateString) {
      this.startTimeStr = dateString;
    },
    changeEndTime(date, dateString) {
      this.endTimeStr = dateString;
    },
    pickItems() {
      this.itemFlag = true;
    },
    handleOk(e) {
      this.itemLoading = true;
      let list = this.itemList;
      let keyList = this.selectedRowKeys;
      let clist = [];
      list.forEach((val) => {
        if (keyList.indexOf(val.id) > -1) {
          clist.push(val);
        }
      });
      clist = this.setColor(clist);
      this.currentItemList = clist;
      this.getData();
      this.itemFlag = false;
    },
    handleCancel(e) {
      this.itemFlag = false;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 更改数据日期
    searchByDate(type) {
      if (this.currentItemList.length < 1) {
        this.$message.info("请先选择监查项");
        return;
      }
      if (type == "reset") {
        this.dateFlag = "day";
      }
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
#assetChart {
  height: 100%;
}
.center{
  scrollbar-width: none;
}
.report-env::-webkit-scrollbar,.center::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.a-modal-sty /deep/ .ant-modal {
  height: 80% !important;
}
.a-modal-sty /deep/ .ant-modal-content {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}
.a-modal-sty /deep/ .ant-modal-body {
  height: 100%;
  overflow-y: auto;
}
.a-modal-sty /deep/ .ant-modal-body::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.a-modal-sty /deep/ .ant-modal-body::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #1890ff;
}
.a-modal-sty /deep/ .ant-modal-body::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
.report-env {
  padding: 0 24px 14px 24px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  scrollbar-width: none;
  color: #333;
  .left {
    width: 15%;
    min-width: 240px;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: #333;
      margin-left: 12px;
      height: 28px;
    }
    .list-box::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .list-box {
      padding: 12px 12px 0 12px;
      overflow-y: auto;
      scrollbar-width: none;
      height: calc(100% - 28px);
      .li {
        background: rgba(245, 245, 245, 0.4);
        border-radius: 8px;
        margin-bottom: 12px;
        padding: 14px;
        color: #333;
        min-height: 80px;
        cursor: pointer;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1),
          0 3px 10px 0 rgba(0, 0, 0, 0.1);
        .label {
          font-size: 16px;
          margin-left: 18px;
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 4px;
          .circle {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: red;
            position: absolute;
            left: -18px;
          }
        }
        .info {
          font-size: 14px;
          margin-left: 18px;
        }
      }
      .add {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon-box {
          width: 50px;
          margin-bottom: 8px;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  .center {
    width: 70%;
    padding-top: 14px;
    margin: 0 14px;
    overflow-y: auto;
    .top {
      height: 500px;
      width: 100%;
      position: relative;
      .loading-sty {
        z-index: 100;
      }
      .nullData {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .bottom {
      display: flex;
      // align-items: center;
      height: calc(100% - 500px);
      .b-item {
        flex: 1;
        height: 100%;
        padding: 4px;
        .label {
          font-size: 16px;
          // margin-bottom: 14px;
          height: 32px;
        }
        .b-content {
          height: calc(100% - 32px);
          position: relative;
          z-index: 50;
        min-height: 200px;
          .loading-sty {
            z-index: 100;
          }
          .nullData {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .total-box {
          .t-item {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e4e7ed;
            .info-center {
              width: 70% !important;
              display: flex;
              justify-content: flex-start;
            }
            .info-left {
              display: flex;
              justify-content: flex-start;
            }
            .info-item {
              width: 15%;
              text-align: center;
              padding: 8px 8px;
            }
          }
        }
      }
      .division {
        width: 14px;
        height: auto;
        position: relative;
      }
      .division::after {
        position: absolute;
        content: " ";
        left: 50%;
        top: 0;
        height: 100%;
        background: rgb(230, 230, 230);
        width: 1px;
      }
    }
  }
  .right {
    width: 15%;
    .title {
      font-size: 16px;
      color: #333;
      font-weight: bold;
      margin-bottom: 14px;
    }
    .select-box {
      padding: 8px 4px;
      .s-item {
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;
        margin-bottom: 14px;
        .icon {
          width: 24px;
          height: 24px;
          img {
            width: 100%;
            height: auto;
          }
        }
        .label {
          margin-left: 8px;
        }
      }
      .item-box {
        margin-bottom: 14px;
      }
    }
  }
}
.column-img-box {
  .col-img {
    width: 80px;
    height: auto;
  }
}
// 鼠标移入效果
@keyframes myCardHover {
  0% {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }
}
.li:hover {
  animation: myCardHover 0.0001s linear;
  animation-fill-mode: both;
}
</style>
