var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-env"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title"},[_vm._v("统计的监测项")]),_c('div',{staticClass:"list-box"},[_c('div',{staticClass:"li add",on:{"click":_vm.pickItems}},[_vm._m(0),_c('div',[_vm._v("添加监测项")])]),_vm._l((_vm.currentItemList),function(item,index){return _c('div',{key:index,staticClass:"li"},[_c('div',{staticClass:"label"},[_c('div',{staticClass:"circle",style:({ background: item.color })}),_c('span',[_vm._v(_vm._s(item.label))])]),_c('div',{staticClass:"info"},[_vm._v(_vm._s(item.farmName))])])})],2)]),_c('div',{staticClass:"center"},[_c('div',{staticClass:"top"},[_c('div',{attrs:{"id":"envChart"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataLoading || _vm.loading),expression:"dataLoading || loading"}],staticClass:"nullData loading-sty"},[_c('a-spin')],1),(_vm.currentItemList.length < 1 || _vm.reportData.chart.length == 0)?_c('rb-empty',{attrs:{"tips":_vm.currentItemList.length > 0 ? '暂无数据。' : '暂无数据，请先选择监测项。'}}):_vm._e()],1),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"b-item"},[_c('div',{staticClass:"label"},[_vm._v("预警次数")]),_c('div',{staticClass:"b-content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataLoading),expression:"dataLoading"}],staticClass:"nullData loading-sty"},[_c('a-spin')],1),(
              _vm.currentItemList.length < 1 || _vm.reportData.warning.length == 0
            )?_c('rb-empty',{attrs:{"tips":_vm.currentItemList.length > 0
                ? '暂无数据。'
                : '暂无数据，请先选择监测项。'}}):_vm._e(),_c('div',{staticClass:"lin-box"},_vm._l((_vm.reportData.warning),function(aitem,aindex){return _c('div',{key:aindex,staticClass:"lin-item"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(aitem.label))]),_c('div',{staticClass:"progress-box"},[_c('a-progress',{attrs:{"stroke-color":{
                    '0%': '#108ee9',
                    '100%': '#87d068',
                  },"percent":aitem.percent,"show-info":false}})],1),_c('div',{staticClass:"num"},[_vm._v(_vm._s(aitem.num)+"次")])])}),0)],1)]),_c('div',{staticClass:"division"}),_c('div',{staticClass:"b-item"},[_c('div',{staticClass:"label"},[_vm._v("最佳生长环境占比")]),_c('div',{staticClass:"b-content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataLoading),expression:"dataLoading"}],staticClass:"nullData loading-sty"},[_c('a-spin')],1),(
              _vm.currentItemList.length < 1 ||
              _vm.reportData.growingEnvironmentPercentage.length == 0
            )?_c('rb-empty',{attrs:{"tips":_vm.currentItemList.length > 0
                ? '暂无数据。'
                : '暂无数据，请先选择监测项。'}}):_vm._e(),_c('div',{staticClass:"lin-box"},_vm._l((_vm.reportData.growingEnvironmentPercentage),function(nitem,nindex){return _c('div',{key:nindex,staticClass:"lin-item"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(nitem.label))]),_c('div',{staticClass:"progress-box"},[_c('a-progress',{attrs:{"percent":parseInt(nitem.percent * 100),"show-info":false}})],1),_c('div',{staticClass:"num"},[_vm._v(_vm._s(parseInt(nitem.percent * 100))+"%")])])}),0)],1)])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v("统计时间段")]),_c('div',{staticClass:"select-box"},[_vm._l((_vm.dateList),function(item,index){return _c('div',{key:index,staticClass:"s-item",on:{"click":function($event){return _vm.pickDateType(item)}}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":item.flag == _vm.dateFlag
                ? require('../../img/pick.png')
                : require('../../img/unpick.png'),"alt":""}})]),_c('div',{staticClass:"label"},[_vm._v(_vm._s(item.label))])])}),(_vm.dateFlag == 'set')?_c('div',{staticClass:"item-box"},[_c('a-date-picker',{staticStyle:{"margin-bottom":"8px"},attrs:{"placeholder":"请选择开始日期","format":"YYYY-MM-DD 00:00:00"},on:{"change":_vm.changeStarTime},model:{value:(_vm.startTimeStr),callback:function ($$v) {_vm.startTimeStr=$$v},expression:"startTimeStr"}}),_c('a-date-picker',{attrs:{"placeholder":"请选择结束日期","format":"YYYY-MM-DD 23:59:59"},on:{"change":_vm.changeEndTime},model:{value:(_vm.endTimeStr),callback:function ($$v) {_vm.endTimeStr=$$v},expression:"endTimeStr"}})],1):_vm._e(),_c('div',{staticClass:"item-box"},[_c('a-button',{staticStyle:{"margin-right":"8px","margin-bottom":"8px"},attrs:{"size":"small"},on:{"click":function($event){return _vm.searchByDate('reset')}}},[_vm._v(" 重置 ")]),_c('a-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.searchByDate}},[_vm._v(" 确认 ")])],1)],2)]),_c('a-modal',{staticClass:"a-modal-sty",attrs:{"title":"选择监测项","width":"800px","visible":_vm.itemFlag,"confirm-loading":_vm.itemLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('div',{staticClass:"items-box"},[_c('div',{staticClass:"items-list"},[_c('div',{staticClass:"items-li items-head",on:{"click":function($event){return _vm.handleItemsPickAll('all')}}},[_c('div',{staticClass:"pick-icon"},[_c('img',{attrs:{"src":_vm.itemHasPick > 0
                  ? require('../../img/item_all.png')
                  : require('../../img/item_unall.png'),"alt":""}})]),_c('span',{staticClass:"pick-name"},[_vm._v("监测项")])]),_vm._l((_vm.itemList),function(item,index){return _c('div',{key:index,staticClass:"items-li items-li-son",on:{"click":function($event){return _vm.handleItemsPick(index)}}},[_c('div',{staticClass:"il-head"},[_c('div',{staticClass:"pick-icon"},[_c('img',{attrs:{"src":item.pickNum > 0
                    ? require('../../img/item_pick.png')
                    : require('../../img/item_unpick.png'),"alt":""}})]),_c('span',{staticClass:"pick-name"},[_vm._v(_vm._s(item.describe))])]),_vm._l((item.point),function(items,indexs){return _c('div',{key:indexs,staticClass:"il-box",on:{"click":function($event){$event.stopPropagation();return _vm.handleSolePick(index, indexs)}}},[_c('div',{staticClass:"pick-icon"},[_c('img',{attrs:{"src":items.pick
                    ? require('../../img/item_pick.png')
                    : require('../../img/item_unpick.png'),"alt":""}})]),_c('span',{staticClass:"pick-name"},[_vm._v(_vm._s(items.farmName))])])})],2)})],2)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon-box"},[_c('img',{attrs:{"src":require("../../img/add.png"),"alt":""}})])}]

export { render, staticRenderFns }