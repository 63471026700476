var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-env"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title"},[_vm._v("种植品种")]),_c('div',{staticClass:"list-box"},[_c('div',{staticClass:"li add",on:{"click":_vm.pickItems}},[_vm._m(0),_c('div',[_vm._v("添加品种")])]),_vm._l((_vm.currentItemList),function(item,index){return _c('div',{key:index,staticClass:"li"},[_c('div',{staticClass:"label"},[_c('div',{staticClass:"circle",style:({ background: item.color })}),_c('span',[_vm._v(_vm._s(item.name))])])])})],2)]),_c('div',{staticClass:"center"},[_c('div',{staticClass:"top"},[_c('div',{attrs:{"id":"cropChart"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataLoading || _vm.loading),expression:"dataLoading || loading"}],staticClass:"nullData loading-sty"},[_c('a-spin')],1),(_vm.currentItemList.length < 1 || _vm.reportData.timeChart.length == 0)?_c('rb-empty',{attrs:{"tips":_vm.currentItemList.length > 0
            ? '暂无数据。'
            : '暂无数据，请先选择监测项。'}}):_vm._e()],1),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"b-item"},[_c('div',{staticClass:"label"},[_vm._v("任务完成率")]),_c('div',{staticClass:"b-content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataLoading),expression:"dataLoading"}],staticClass:"nullData loading-sty"},[_c('a-spin')],1),(
              _vm.currentItemList.length < 1 || _vm.reportData.finishChart.length == 0
            )?_c('rb-empty',{attrs:{"tips":_vm.currentItemList.length > 0
                ? '暂无数据。'
                : '暂无数据，请先选择监测项。'}}):_vm._e(),_c('div',{staticClass:"lin-box"},_vm._l((_vm.reportData.finishChart),function(fitem,findex){return _c('div',{key:findex,staticClass:"lin-item"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(fitem.item))]),_c('div',{staticClass:"progress-box"},[_c('a-progress',{attrs:{"percent":parseInt(fitem.value * 100),"show-info":false}})],1),_c('div',{staticClass:"num"},[_vm._v(_vm._s(parseInt(fitem.value * 100))+"%")])])}),0)],1)]),_c('div',{staticClass:"division"}),_c('div',{staticClass:"b-item"},[_c('div',{staticClass:"label"},[_vm._v("任务类型统计")]),_c('div',{staticClass:"b-content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataLoading),expression:"dataLoading"}],staticClass:"nullData loading-sty"},[_c('a-spin')],1),(
              _vm.currentItemList.length < 1 || _vm.reportData.groupChart.length == 0
            )?_c('rb-empty',{attrs:{"tips":_vm.currentItemList.length > 0
                ? '暂无数据。'
                : '暂无数据，请先选择监测项。'}}):_vm._e(),_c('div',{staticClass:"lin-box"},_vm._l((_vm.reportData.groupChart),function(gitem,findex){return _c('div',{key:findex,staticClass:"lin-item"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(gitem.item))]),_c('div',{staticClass:"progress-box"},[_c('a-progress',{attrs:{"percent":parseInt(gitem.value),"show-info":false}})],1),_c('div',{staticClass:"num"},[_vm._v(_vm._s(parseInt(gitem.value))+"次")])])}),0)],1)])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v("统计时间段")]),_c('div',{staticClass:"select-box"},[_vm._l((_vm.dateList),function(item,index){return _c('div',{key:index,staticClass:"s-item",on:{"click":function($event){return _vm.pickDateType(item)}}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":item.flag == _vm.dateFlag
                ? require('../../img/pick.png')
                : require('../../img/unpick.png'),"alt":""}})]),_c('div',{staticClass:"label"},[_vm._v(_vm._s(item.label))])])}),(_vm.dateFlag == 'set')?_c('div',{staticClass:"item-box"},[_c('a-date-picker',{staticStyle:{"margin-bottom":"8px"},attrs:{"placeholder":"请选择开始日期","format":"YYYY-MM-DD 00:00:00"},on:{"change":_vm.changeStarTime},model:{value:(_vm.startTimeStr),callback:function ($$v) {_vm.startTimeStr=$$v},expression:"startTimeStr"}}),_c('a-date-picker',{attrs:{"placeholder":"请选择结束日期","format":"YYYY-MM-DD 23:59:59"},on:{"change":_vm.changeEndTime},model:{value:(_vm.endTimeStr),callback:function ($$v) {_vm.endTimeStr=$$v},expression:"endTimeStr"}})],1):_vm._e(),_c('div',{staticClass:"item-box"},[_c('a-button',{staticStyle:{"margin-right":"8px","margin-bottom":"8px"},attrs:{"size":"small"},on:{"click":function($event){return _vm.searchByDate('reset')}}},[_vm._v(" 重置 ")]),_c('a-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.searchByDate}},[_vm._v(" 确认 ")])],1)],2)]),_c('a-modal',{staticClass:"a-modal-sty",attrs:{"title":"选择种植品种","width":"70%","visible":_vm.itemFlag,"confirm-loading":_vm.itemLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-table',{staticClass:"ant-table-sty",attrs:{"row-selection":{
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange,
      },"columns":_vm.columns,"data-source":_vm.itemList,"pagination":_vm.pagination,"size":"small"},scopedSlots:_vm._u([{key:"imgPathAll",fn:function(row){return [_c('div',{staticClass:"column-img-box"},[_c('img',{staticClass:"col-img",attrs:{"src":row,"alt":""}})])]}}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon-box"},[_c('img',{attrs:{"src":require("../../img/add.png"),"alt":""}})])}]

export { render, staticRenderFns }