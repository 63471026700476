<template>
  <div class="report-env">
    <div class="left">
      <div class="title">统计的监测项</div>
      <div class="list-box">
        <div class="li add" @click="pickItems">
          <div class="icon-box"><img src="../../img/add.png" alt="" /></div>
          <div>添加监测项</div>
        </div>
        <div class="li" v-for="(item, index) in currentItemList" :key="index">
          <div class="label">
            <div class="circle" :style="{ background: item.color }"></div>
            <span>{{ item.label }}</span>
          </div>
          <div class="info">{{ item.farmName }}</div>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="top">
        <div id="envChart"></div>
        <div class="nullData loading-sty" v-show="dataLoading || loading">
          <a-spin />
        </div>
        <!-- <div
          class="nullData"
          v-if="!itemHasPick || reportData.chart.length == 0"
        >
          {{ itemHasPick ? "暂无数据。" : "暂无数据，请先选择监测项。" }}
        </div> -->
        <rb-empty
          v-if="currentItemList.length < 1 || reportData.chart.length == 0"
          :tips="currentItemList.length > 0 ? '暂无数据。' : '暂无数据，请先选择监测项。'"
        />
      </div>
      <div class="bottom">
        <div class="b-item">
          <div class="label">预警次数</div>
          <div class="b-content">
            <div class="nullData loading-sty" v-show="dataLoading">
              <a-spin />
            </div>
            <!-- <div
              class="nullData"
              v-if="
                currentItemList.length < 1 || reportData.warning.length == 0
              "
            >
              {{
                currentItemList.length > 0
                  ? "暂无数据。"
                  : "暂无数据，请先选择监测项。"
              }}
            </div> -->
            <rb-empty
              v-if="
                currentItemList.length < 1 || reportData.warning.length == 0
              "
              :tips="
                currentItemList.length > 0
                  ? '暂无数据。'
                  : '暂无数据，请先选择监测项。'
              "
            />
            <div class="lin-box">
              <div
                class="lin-item"
                v-for="(aitem, aindex) in reportData.warning"
                :key="aindex"
              >
                <div class="name">{{ aitem.label }}</div>
                <div class="progress-box">
                  <a-progress
                    :stroke-color="{
                      '0%': '#108ee9',
                      '100%': '#87d068',
                    }"
                    :percent="aitem.percent"
                    :show-info="false"
                  />
                </div>
                <div class="num">{{ aitem.num }}次</div>
              </div>
              <!-- <div class="lin-item" v-if="reportData.warning.length == 0">
                暂无数据
              </div> -->
            </div>
          </div>
        </div>
        <div class="division"></div>
        <div class="b-item">
          <div class="label">最佳生长环境占比</div>
          <div class="b-content">
            <div class="nullData loading-sty" v-show="dataLoading">
              <a-spin />
            </div>
            <!-- <div
              class="nullData"
              v-if="
                currentItemList.length < 1 ||
                reportData.growingEnvironmentPercentage.length == 0
              "
            >
              {{
                currentItemList.length > 0
                  ? "暂无数据。"
                  : "暂无数据，请先选择监测项。"
              }}
            </div> -->
            <rb-empty
              v-if="
                currentItemList.length < 1 ||
                reportData.growingEnvironmentPercentage.length == 0
              "
              :tips="
                currentItemList.length > 0
                  ? '暂无数据。'
                  : '暂无数据，请先选择监测项。'
              "
            />
            <div class="lin-box">
              <div
                class="lin-item"
                v-for="(
                  nitem, nindex
                ) in reportData.growingEnvironmentPercentage"
                :key="nindex"
              >
                <div class="name">{{ nitem.label }}</div>
                <div class="progress-box">
                  <a-progress
                    :percent="parseInt(nitem.percent * 100)"
                    :show-info="false"
                  />
                </div>
                <div class="num">{{ parseInt(nitem.percent * 100) }}%</div>
              </div>
              <!-- <div
                class="lin-item"
                v-if="reportData.growingEnvironmentPercentage.length == 0"
              >
                暂无数据
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="title">统计时间段</div>
      <div class="select-box">
        <div
          class="s-item"
          v-for="(item, index) in dateList"
          :key="index"
          @click="pickDateType(item)"
        >
          <div class="icon">
            <img
              :src="
                item.flag == dateFlag
                  ? require('../../img/pick.png')
                  : require('../../img/unpick.png')
              "
              alt=""
            />
          </div>
          <div class="label">{{ item.label }}</div>
        </div>
        <div class="item-box" v-if="dateFlag == 'set'">
          <a-date-picker
            placeholder="请选择开始日期"
            v-model="startTimeStr"
            style="margin-bottom: 8px"
            format="YYYY-MM-DD 00:00:00"
            @change="changeStarTime"
          />
          <a-date-picker
            placeholder="请选择结束日期"
            v-model="endTimeStr"
            format="YYYY-MM-DD 23:59:59"
            @change="changeEndTime"
          />
        </div>
        <div class="item-box">
          <a-button
            size="small"
            style="margin-right: 8px; margin-bottom: 8px"
            @click="searchByDate('reset')"
          >
            重置
          </a-button>
          <a-button type="primary" size="small" @click="searchByDate">
            确认
          </a-button>
        </div>
      </div>
    </div>
    <!-- 选择项box -->
    <a-modal
      title="选择监测项"
      width="800px"
      :visible="itemFlag"
      :confirm-loading="itemLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      class="a-modal-sty"
    >
      <div class="items-box">
        <div class="items-list">
          <div class="items-li items-head" @click="handleItemsPickAll('all')">
            <div class="pick-icon">
              <img
                :src="
                  itemHasPick > 0
                    ? require('../../img/item_all.png')
                    : require('../../img/item_unall.png')
                "
                alt=""
              />
            </div>
            <span class="pick-name">监测项</span>
          </div>
          <div
            class="items-li items-li-son"
            v-for="(item, index) in itemList"
            :key="index"
            @click="handleItemsPick(index)"
          >
            <div class="il-head">
              <div class="pick-icon">
                <img
                  :src="
                    item.pickNum > 0
                      ? require('../../img/item_pick.png')
                      : require('../../img/item_unpick.png')
                  "
                  alt=""
                />
              </div>
              <span class="pick-name">{{ item.describe }}</span>
            </div>
            <div
              class="il-box"
              v-for="(items, indexs) in item.point"
              :key="indexs"
              @click.stop="handleSolePick(index, indexs)"
            >
              <div class="pick-icon">
                <img
                  :src="
                    items.pick
                      ? require('../../img/item_pick.png')
                      : require('../../img/item_unpick.png')
                  "
                  alt=""
                />
              </div>
              <span class="pick-name">{{ items.farmName }}</span>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";
export default {
  props: {
    history: {},
    colorlist: [],
  },
  data() {
    return {
      loading: false,
      dataLoading: false,
      chart: null,
      searchData: {},
      dateFlag: "day",
      startTimeStr: "",
      endTimeStr: "",
      itemBackList: [],
      itemList: [],
      itemHasPick: false,
      itemLoading: false,
      itemFlag: false,
      currentItemList: [],
      dateList: [
        {
          label: "近一日",
          flag: "day",
        },
        {
          label: "近七日日",
          flag: "week",
        },
        {
          label: "近一个月",
          flag: "month",
        },
        {
          label: "自定义",
          flag: "set",
        },
      ],
      reportData: {
        chart: [],
        growingEnvironmentPercentage: [],
        warning: [],
      },
      colorList: [],
    };
  },
  created() {},
  mounted() {
    this.colorList = this.colorlist;
    let history = JSON.parse(JSON.stringify(this.history));
    if (history.itemList.length > 0) {
      this.itemList = history.itemList;
      this.currentItemList = history.currentItemList;
      this.dateFlag = history.dateFlag;
      this.startTimeStr = history.startTimeStr;
      this.endTimeStr = history.endTimeStr;
      this.handleArr(this.itemList);
      this.handleOk();
    } else {
      this.getEnvironmentMeterCollectItem();
    }
  },
  methods: {
    // 获取检测项列表
    getEnvironmentMeterCollectItem() {
      this.http.report.getEnvironmentMeterCollectItem().then((res) => {
        if (res.data.status == "200") {
          let list = res.data.data;
          list.forEach((val) => {
            val.pickNum = 0;
            val.point.forEach((vall) => {
              vall.pick = false;
            });
          });
          this.itemList = list;
        } else {
          this.$message.info("获取监测项失败，稍后再试");
        }
      });
    },
    // 更改数据日期
    searchByDate(type) {
      if (this.currentItemList.length < 1) {
        this.$message.info("请先选择监查项");
        return;
      }
      if (type == "reset") {
        this.dateFlag = "day";
      }
      this.getData();
    },
    // 获取图表数据
    getData() {
      // 加载
      this.loading = true;
      this.dataLoading = true;
      let data = {};
      let collectItem = [];
      let list = JSON.parse(JSON.stringify(this.currentItemList));
      list.forEach((val) => {
        collectItem.push(val.id);
      });
      data.collectItem = collectItem.join(",");
      if (this.dateFlag != "set") {
        data.day = this.dateFlag;
      } else {
        data.startTimeStr = this.startTimeStr;
        data.endTimeStr = this.endTimeStr;
      }
      this.http.report
        .getEnvironmentReport(data)
        .then((res) => {
          if (res.data.status == "200") {
            let data = res.data.data;
            data.chart == null ? data.chart = [] : ''
            data.growingEnvironmentPercentage == null ? data.growingEnvironmentPercentage = [] : ''
            data.warning == null ? data.warning = [] : ''
            this.reportData = data;
            this.itemFlag = false;
            this.itemLoading = false;
            this.dataLoading = false;
          } else {
            this.loading = false;
            this.dataLoading = false;
            // this.$message.error("获取监测数据超时");
          }
        })
        .then(() => {
          this.initColor();
          this.saveSearch();
          this.initChart();
        })
        .catch((err) => {
          this.loading = false;
          this.dataLoading = false;
          // this.$message.error("获取监测数据超时");
          console.log(err);
        });
    },
    initColor() {
      let data = this.reportData;
      data.growingEnvironmentPercentage = this.setColor(
        data.growingEnvironmentPercentage
      );
      data.warning = this.setColor(data.warning);
      this.reportData = data;
    },
    saveSearch() {
      this.$emit("childFn", {
        itemList: this.itemList,
        currentItemList: this.currentItemList,
        dateFlag: this.dateFlag,
        startTimeStr: this.startTimeStr,
        endTimeStr: this.endTimeStr,
      });
    },
    setColor(clist) {
      // 追加颜色
      let colorlist = this.colorList;
      let ci = 0;
      for (let i = 0; i < clist.length; i++) {
        clist[i].color = colorlist[ci];
        if (ci < 11) {
          ci++;
        } else {
          ci = 0;
        }
      }
      return clist;
    },
    handleChartData(data) {
      return data;
    },
    initChart() {
      let that = this;
      let rdata = this.handleChartData(
        JSON.parse(JSON.stringify(this.reportData.chart))
      );
      if(that.chart != null){
        that.chart.data(rdata)
        that.chart.render()
        that.loading = false;
        return;
      }
      that.chart = new Chart({
        container: "envChart",
        autoFit: true,
      });

      that.chart.data(rdata);
      that.chart.scale({
        month: {
          range: [0, 1],
        },
        value: {
          nice: true,
          type: "pow",
        },
      });

      that.chart.tooltip({
        showCrosshairs: true,
        shared: true,
      });
      that.chart.axis("value", {
        label: false,
      });
      that.chart
        .line()
        .position("time*value")
        .color("collectItem", that.colorList)
        .shape("smooth");
      that.$nextTick(() => {
        that.chart.render();
        that.loading = false;
      });
    },
    pickDateType(val) {
      this.dateFlag = val.flag;
    },
    changeStarTime(date, dateString) {
      this.startTimeStr = dateString;
    },
    changeEndTime(date, dateString) {
      this.endTimeStr = dateString;
    },
    pickItems() {
      this.itemBackList = JSON.parse(JSON.stringify(this.itemList))
      this.itemFlag = true;
    },
    handleOk(e) {
      this.itemLoading = true;
      let list = this.itemList;
      let clist = [];
      list.forEach((val) => {
        val.point.forEach((vall) => {
          if (vall.pick) {
            clist.push(vall);
          }
        });
      });
      // 追加颜色
      let colorlist = this.colorList;
      let ci = 0;
      for (let i = 0; i < clist.length; i++) {
        clist[i].color = colorlist[ci];
        if (ci < 11) {
          ci++;
        } else {
          ci = 0;
        }
      }
      this.currentItemList = clist;
      this.getData();
      // this.itemFlag = false;
    },
    handleCancel(e) {
      // this.handleItemsPickAll("cancel");
      this.itemList = JSON.parse(JSON.stringify(this.itemBackList))
      this.itemFlag = false;
    },
    handleItemsPickAll(type) {
      let list = this.itemList;
      for (let i = 0; i < list.length; i++) {
        list[i].point.forEach((val) => {
          val.pick = type == "cancel" ? false : !this.itemHasPick;
        });
      }
      this.handleArr(list);
    },
    handleItemsPick(ind) {
      let list = this.itemList;
      let flag = JSON.parse(JSON.stringify(list[ind].pickNum));
      list[ind].point.forEach((val) => {
        val.pick = flag == 0 ? true : false;
      });
      this.handleArr(list);
    },
    handleSolePick(ind, inds) {
      let list = this.itemList;
      list[ind].point[inds].pick = !list[ind].point[inds].pick;
      this.handleArr(list);
    },
    handleArr(arr) {
      let list = JSON.parse(JSON.stringify(arr))
      list.forEach((val) => {
        val.pickNum = 0;
        val.point.forEach((vall) => {
          if (vall.pick) {
            val.pickNum++;
          }
          vall.label = val.describe;
        });
      });
      let itemHasPick = false;
      list.forEach((val) => {
        if (val.pickNum > 0) {
          itemHasPick = true;
        }
      });
      this.itemList = list;
      this.itemHasPick = itemHasPick;
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
#envChart {
  height: 100%;
}
.center{
  scrollbar-width: none;
}
.report-env::-webkit-scrollbar,.center::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.a-modal-sty /deep/ .ant-modal {
  height: 80% !important;
}
.a-modal-sty /deep/ .ant-modal-content {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}
.a-modal-sty /deep/ .ant-modal-body {
  height: 100%;
  overflow-y: auto;
}
.a-modal-sty /deep/ .ant-modal-body::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.a-modal-sty /deep/ .ant-modal-body::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #1890ff;
}
.a-modal-sty /deep/ .ant-modal-body::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
.report-env {
  padding: 0 24px 14px 24px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  scrollbar-width: none;
  color: #333;
  .left {
    width: 15%;
    min-width: 240px;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: #333;
      margin-left: 12px;
      height: 28px;
    }
    .list-box::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .list-box {
      padding: 12px 12px 0 12px;
      height: calc(100% - 28px);
      overflow-y: auto;
      scrollbar-width: none;
      .li {
        background: rgba(245, 245, 245, 0.4);
        border-radius: 8px;
        margin-bottom: 12px;
        padding: 14px;
        color: #333;
        min-height: 80px;
        cursor: pointer;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1),
          0 3px 10px 0 rgba(0, 0, 0, 0.1);
        .label {
          font-size: 16px;
          margin-left: 18px;
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 4px;
          .circle {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: red;
            position: absolute;
            left: -18px;
          }
        }
        .info {
          font-size: 14px;
          margin-left: 18px;
        }
      }
      .add {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon-box {
          width: 50px;
          margin-bottom: 8px;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  .center {
    width: 70%;
    padding-top: 14px;
    margin: 0 14px;
    overflow-y: auto;
    .top {
      height: 500px;
      width: 100%;
      position: relative;
      .loading-sty {
        z-index: 100;
      }
      .nullData {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .bottom {
      display: flex;
      height: calc(100% - 500px);
      // align-items: center;
      .b-item {
        flex: 1;
        height: 100%;
        padding: 4px;
        .label {
          font-size: 16px;
          margin-bottom: 14px;
        }
        .lin-box {
          padding: 0 14px;
          .lin-item:not(:last-child) {
            margin-bottom: 8px;
          }
          .lin-item {
            display: flex;
            align-items: center;
            .name {
              width: 15%;
              min-width: 60px;
            }
            .progress-box {
              flex: 1;
              margin: 0 8px 0 8px;
            }
            .num {
              width: 15%;
              min-width: 60px;
            }
          }
        }
      }
      .b-content {
        height: calc(100% - 32px);
        min-height: 200px;
        position: relative;
        z-index: 50;
        .loading-sty {
          z-index: 100;
        }
        .nullData {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .division {
        width: 14px;
        height: auto;
        position: relative;
      }
      .division::after {
        position: absolute;
        content: " ";
        left: 50%;
        top: 0;
        height: 100%;
        background: rgb(230, 230, 230);
        width: 1px;
      }
    }
  }
  .right {
    width: 15%;
    .title {
      font-size: 16px;
      color: #333;
      font-weight: bold;
      margin-bottom: 14px;
    }
    .select-box {
      padding: 8px 4px;
      .s-item {
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;
        margin-bottom: 14px;
        .icon {
          width: 24px;
          height: 24px;
          img {
            width: 100%;
            height: auto;
          }
        }
        .label {
          margin-left: 8px;
        }
      }
      .item-box {
        margin-bottom: 14px;
      }
    }
  }
}
.items-box {
  width: 100%;
  height: 100%;
  .items-list {
    .items-head {
      background: #ebeef5;
    }
    .il-head {
      margin-bottom: 8px;
    }
    .il-head,
    .il-box {
      display: flex;
      align-items: center;
    }
    .il-box {
      padding-left: 14px;
    }
    .items-li-son {
      flex-direction: column !important;
      align-items: flex-start !important;
    }
    .items-li {
      padding: 14px 8px;
      border-bottom: 1px solid #e4e7ed;
      cursor: pointer;
      display: flex;
      align-items: center;
      .pick-icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
// 鼠标移入效果
@keyframes myCardHover {
  0% {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }
}
.li:hover {
  animation: myCardHover 0.0001s linear;
  animation-fill-mode: both;
}
</style>
