<template>
  <div class="report">
    <div class="nav-box">
      <div
        class="nav-item"
        :class="item.id == currentNav ? 'active' : ''"
        v-for="(item, index) in navList"
        :key="index"
        @click="pickNav(item.id)"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="main">
      <EnvironmentReport
        v-if="currentNav == 'fm1'"
        :history="historySearch.fm1"
        :colorlist="colorList"
        @childFn="handleHistory1"
      />
      <CropManagementReport
        v-if="currentNav == 'fm2'"
        :history="historySearch.fm2"
        :colorlist="colorList"
        @childFn="handleHistory2"
      />
      <AssetWarehouseReport
        v-if="currentNav == 'fm3'"
        :history="historySearch.fm3"
        :colorlist="colorList"
        @childFn="handleHistory3"
      />
    </div>
  </div>
</template>

<script>
// 农资报表
import AssetWarehouseReport from "./assetWarehouseReport";
// 任务报表
import CropManagementReport from "./cropManagementReport";
// 环境报表
import EnvironmentReport from "./environmentReport";
export default {
  components: {
    AssetWarehouseReport,
    CropManagementReport,
    EnvironmentReport,
  },
  data() {
    return {
      navList: [
        {
          label: "环境报表",
          id: "fm1",
        },
        {
          label: "任务报表",
          id: "fm2",
        },
        {
          label: "农资报表",
          id: "fm3",
        },
      ],
      currentNav: "fm1",
      // 监控浏览器窗口变化
      screenWidth: document.body.clientWidth,
      timer: false,
      historySearch: {
        fm1: {
          currentItemList: [],
          dateFlag: "",
          endTimeStr: "",
          itemList: [],
          startTimeStr: "",
        },
        fm2: {
          currentItemList: [],
          dateFlag: "",
          endTimeStr: "",
          itemList: [],
          startTimeStr: "",
          selectedRowKeys: []
        },
        fm3: {
          currentItemList: [],
          dateFlag: "",
          endTimeStr: "",
          itemList: [],
          startTimeStr: "",
          selectedRowKeys: []
        },
      },
      // 图表颜色列表
      colorList: [
        "#9966ff",
        "#6666ff",
        "#6699ff",
        "#66ff99",
        "#ffff66",
        "#ffcc66",
        "#ff9966",
        "#ff6666",
        "#abcdef",
        "#bedcaf",
        "#cafedb",
        "#decafb",
      ],
    };
  },
  mounted() {
    let that = this;
    // 添加改变窗口大小改变监听
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
  },
  watch: {
    screenWidth(val) {
      let that = this;
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!that.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        that.screenWidth = val;
        that.timer = true;
        setTimeout(function () {
          that.uploadTemp();
          that.timer = false;
        }, 500);
      }
    },
  },
  methods: {
    pickNav(e) {
      this.currentNav = e;
    },
    uploadTemp() {
      let that = this;
      let change = JSON.parse(JSON.stringify(this.currentNav));
      that.currentNav = "";
      that.$nextTick(() => {
        that.currentNav = change;
      });
    },
    handleHistory1(val){
      let data = this.historySearch
      data.fm1 = JSON.parse(JSON.stringify(val))
      this.historySearch = data
    },
    handleHistory2(val){
      let data = this.historySearch
      data.fm2 = JSON.parse(JSON.stringify(val))
      this.historySearch = data
    },
    handleHistory3(val){
      let data = this.historySearch
      data.fm3 = JSON.parse(JSON.stringify(val))
      this.historySearch = data
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.report {
  background: #fff;
  // padding: 0 24px 14px 24px;
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  .nav-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    .nav-item {
      padding: 16px 18px;
      margin: 0 4px;
      font-size: 16px;
      cursor: pointer;
      position: relative;
    }
    .active {
      color: #409eff;
    }
    .active::after {
      // color: #409eff;
      // border-bottom: 2px solid #409eff;
      content: " ";
      width: 100%;
      height: 2px;
      background: #409eff;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .main {
    height: calc(100% - 70px);
  }
}
</style>